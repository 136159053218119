import lottie from 'lottie-web';
import './styles/sp.less';
import './js/polyfills';
import './js/menu';


const options = {
  rootMargin: '0% 0% -25% 0%',
  threshold: .1,
};
const animatedBlocks = Array.from(document.querySelectorAll('[data-animated]'));
const adnimatedBlocksObserver = new IntersectionObserver((entries) => {
  entries.forEach((entry) => {
    if (entry.isIntersecting) {
      entry.target.classList.add('_animate');
    }
  });
}, options);

animatedBlocks.forEach((block) => adnimatedBlocksObserver.observe(block));

const lottieAnimations = {
  office: require('./assets/json/girl.json').default,
  main_girl: require('./assets/json/girl.json').default,
  main_drone_left: require('./assets/json/drone-left.json').default,
  main_drone_right: require('./assets/json/drone-right.json').default,
  research: require('./assets/json/drone-left.json').default,
  bigdata: require('./assets/json/bigdata.json').default,
  development: require('./assets/json/development.json').default,
  backoffice: require('./assets/json/backoffice.json').default,
  marketing: require('./assets/json/marketing.json').default,
};
const lottieContainers = Array.from(document.querySelectorAll('[data-lottie]'));
const lottieObserver = new IntersectionObserver((entries) => {
  entries.forEach((entry) => {
    const name = entry.target.dataset.lottie;

    if (entry.isIntersecting) {
      lottie.play(name);
    } else {
      lottie.pause(name);
    }
  });
}, { threshold: .1 });

lottieContainers.forEach((container) => {
  lottie.loadAnimation({
    container: container,
    path: lottieAnimations[container.dataset.lottie],
    name: container.dataset.lottie,
    renderer: 'svg',
    loop: true,
    autoplay: false,
    rendererSettings: {
      className: 'lottie-canvas',
    },
  });

  lottieObserver.observe(container);
});
