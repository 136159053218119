const burger = document.getElementById('header-burger');
const nav = document.getElementById('menu-nav');

burger.addEventListener('click', () => {
  if (burger.classList.contains('_active')) {
    closeMenu();
  } else {
    openMenu();
  }
});

// nav.addEventListener('click', (e) => {
//   if (!e.target.href) {
//     return;
//   }

//   const href = e.target.getAttribute('href');
//   const anchor = document.getElementById(href.replace('#', ''));

//   e.preventDefault();
//   closeMenu();

//   anchor.scrollIntoView({
//     behavior: 'smooth'
//   });
// });

function openMenu() {
  burger.classList.add('_active');
  document.body.classList.add('_menu-active');
}

function closeMenu() {
  burger.classList.remove('_active');
  document.body.classList.remove('_menu-active');
}
